@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #e0e0e0;
  background: #121212;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-wrap: wrap;
}

@media (max-width: 1000px){
  .body{
    font-size: .5rem;
  }
}
