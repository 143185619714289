.icon {
    position: absolute;
    background: none;
    border: none;
    cursor: pointer;
    top: 5px;
    opacity: 0.5;
    transition: opacity 0.4s;
    z-index: 20;

    &:hover {
        opacity: 1;
    }

    svg {
        fill: white;
        width: 24px;
        height: 24px;
    }
}

.info-toggle {
    right: 75px;
}

.fullscreen-toggle{
    right: 40px;
}

.configurator-toggle {
    right: 5px;
}

.fretboard-configurator {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    overflow: hidden;
    transition: width 0.5s ease-in-out;
    z-index: 10;
    opacity: .8;
    padding: 5px;
    display: none;
}

.fretboard-configurator.open {
    width: 500px;
    display: block;
}

.canvas-container {
    width: 100%;
    max-height: 600px;
    height: 100vh;
}

.fretboard-canvas {
    width: 100%;
    height: 100%;
    display: block;
}

@media (max-width: 768px) {
    .canvas-container {
        height: 100vh;
    }
}

