.note-detector {
  width: 100%;
  height: 100%;
  padding: .7rem;
}



@media (max-width: 1000px){
  .note-detector{
    font-size: 0.9rem;
  }
}


